<template>
    <div class="content">
        <p class="description">精读圣经是一款功能强大，资源丰富，可完全离线使用的圣经工具。<br>电脑版有专门的投影模块，投影非常赞！除此之外还含有模块制作功能，可用于制作译本、注释、灵修、辞典及读经计划。制作的资料可以上传服务器，以「口令」的形式提供下载，也可以使用「数据传输」功能分发到其他设备。</p>
        <div class="os">
            <h2><span class="icon iconfont">&#xe680;&#xe681;&#xe683;</span>Android</h2>
            <p class="info">华为（鸿蒙5开始不支持），华为、荣耀、OPPO、VIVO、小米等品牌，不是苹果的就选这个吧。</p>
            <p><span style='color: #46B8DA'>最近不少使用VIVO手机的弟兄姊妹反馈打开App会弹出广告，这可能与快应用有关。有热心姊妹提供解决方法，可以试试：</span><br>
            <span style='color: #4CAE4C'>打开系统设置->应用与权限->系统应用设置->快应用->快应用管理->快应用跳转管理->App内跳转快应用 *这一项关掉* <a href="qa3.htm" role="button">查看截图</a></span></p>
            <a-button id="button" type="primary" @click="openHref('bible apk', bibleApkUrl)">v{{bibleApkVersion}} 下载</a-button>											
        </div>	
        <div class='divider'></div>
        <div class="os">
            <h2><span class="icon iconfont">&#xe685;&#xe682;</span>iPhone、iPad</h2>
            <p v-if="false">请仔细看下面两种方式的操作流程。<span style='color: red'>推荐使用方式一</span>，通过Appstore下载安全稳定。方式二虽然安装方便，但可能存在掉签风险，掉签后无法安装，已安装的也会无法使用。</p>
            <p><span style='color: darkslateblue'>方式一：因未在中国大陆AppStore上线，可以通过<a href="https://appleid.apple.com" role="button">更改账号地区到港澳台</a>后，打开AppStore，退出账号重新登录后下载。对于更新，如不成功，请先删除之前下载的版本。</span></p>
            <p><span style='color: green'>方式二：使用轻松签、全能签或者易签助手的可以添加源地址（http://christapps.org/appstore）直接下载安装。 （ios14-15的一些设备可能可以免费使用轻松签，其他的需要购买证书。）。</span></p>					
            <p><span style='color: green'>方式三：可以通过电脑使用爱思助手签名的，可以下载安装包，重新签名后使用。爱思助手支持使用自己的苹果账号免费签名，有效7天，过期再重签。</span></p>					
            <a-space>
                <a-button id="button" type="primary" @click="openHref('html', 'appleid.htm')">换区流程</a-button>
                <a-button id="button" type="primary" @click="openHref('html', 'https://appleid.apple.com')">更换地区</a-button>
                <a-button id="button" type="primary" @click="openHref('bible ipa', bibleIpaUrl)" v-if="bibleIpaUrl!=''">v{{bibleIpaVersion}} 下载（不会签名的不要下载）</a-button>	
            </a-space>
        </div>	
        <div class='divider'></div>
        <div class="os">
            <h2><span class="icon iconfont">&#xe684;</span>Windows电脑</h2>
            <p class="info">适用于Win7到Win11;UWP版本，适用于Win10、Win11;</p>					
            <span style='color: darkorange'>Win10系统使用Edge浏览器下载可能遇到的问题，</span><a href="qa.htm" role="button">点这里查看。</a><br>
            <span style='color: darkgoldenrod'>win10系统1803版本（18年3月）开始可以下载UWP版本，两个版本不冲突可以同时安装，哪个好用用哪个。安装前需要在设置里打开“开发人员模式”，同时安装证书，</span><a href="qa2.htm" role="button">点这里可以查看图文说明。</a><br>
            <a-space>
                <a-button id="button" type="primary" @click="openHref('bible exe', bibleExeUrl)">Win v{{bibleExeVersion}} 下载</a-button>	
                <a-button id="button" type="primary" @click="openHref('bible appx', bibleAppxUrl)">UWP v{{bibleAppxVersion}} 下载</a-button>	
            </a-space>
        </div>
        <div class='divider'></div>
        <div class="os">
            <h2><span class="icon iconfont">&#xe67f;</span>苹果电脑</h2>
            <p class="info">10.15版本开始支持。</p>		
            <a-button id="button" type="primary" @click="openHref('bible mac', bibleDmgUrl)">v{{bibleDmgVersion}} 下载</a-button>
        </div>
    </div>   
</template>

<script>
export default {
    name: 'BibleView',
    props: {

    },
    data() {
        return {
            showWechat: false,
            bibleApkVersion: '3.6.1',
            bibleApkUrl: '/app/bible.apk',
            bibleExeVersion: '3.6.1',
            bibleExe: '/app/bible.exe',
            bibleAppxVersion: '3.6.1',
            bibleAppx: '/app/bible.appxbundle',
            bibleDmgVersion: '3.6.1',
            bibleDmg: '/app/bible.dmg',
            bibleIpaVersion: '4.7',
            bibleIpa: '/app/bible.ipa',
        };
    },
    computed: {

    },
    setup() {
        
    },
    created() {
        if (this.global.is_weixin()) {
            this.showWechat = true
        }
        var text = this.global.loadFile('url.json')
        console.log(text)
        var json = JSON.parse(text)
        if (json == null) return
        this.bibleApkVersion = json["bible"]["apkVersion"]
        this.bibleApkUrl = json["bible"]["apkUrl"]
        this.bibleExeVersion = json["bible"]["exeVersion"]
        this.bibleExeUrl = json["bible"]["exeUrl"]
        this.bibleAppxVersion = json["bible"]["appxVersion"]
        this.bibleAppxUrl = json["bible"]["appxUrl"]
        this.bibleDmgVersion = json["bible"]["dmgVersion"]
        this.bibleDmgUrl = json["bible"]["dmgUrl"]
        this.bibleIpaVersion = json["bible"]["ipaVersion"]
        this.bibleIpaUrl = json["bible"]["ipaUrl"]
    },
    mounted() {

    },
    watch: {

    },
    methods: {
        openHref (category, url) {
            window.location.href = url
            this.$gtag.event('button', { 'event_category': category, 'event_label': url })
            this.$ba.trackEvent(category, 'button', url, '')
        },
    },
    components: {

    },
};
</script>

<style scoped>

.content {
    display:inline-block;
    text-align: left;
    max-width: 800px;
}
.wechat {
    position:relative;
    padding-top:20px;
    padding-bottom: 10px;
    color: black;
    background-color: #ebe7e7;
    font-size: 22px;
    text-align: center;
}
.red {
    color: red;
}
.description {
    color: gray;
    padding: 10px;
    -webkit-overflow-scrolling:touch;
    background-color:#efeff4;
}
.info {
    color: gray;
}
.os {
    padding: 10px;
}
p {
    margin: 0px;
}
a-divider {
    height: 1px;
}
.divider {
    width: 100%;
    height: 1px;
    position: relative;
    background-color: #eeeeee;
}
</style>
